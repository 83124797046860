

@import "../../bootstrap/variables.less";

@body-bg:					#900000;

@text-color:				#fff;
@link-color:				orange;
@link-hover-color:			orange;
@link-hover-decoration:		underline;

@font-family-base:			"Comic Sans MS", Arial, sans;
@font-size-base:			12px;
@line-height-base:			1;

@font-size-h1:				24px;
@headings-small-color:		#fff;

@thumb-width:				252px;
@thumb-height:				182px;
@thumb-margin-top:			0;
@thumb-margin-right:		0;
@thumb-margin-bottom:		0;
@thumb-margin-left:			0;
@thumb-border-width:		1px;
@thumb-border:				@thumb-border-width solid #fff;
@thumb-hover-border:		@thumb-border-width solid #fff;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#fff;
@popup-text-color:			#000;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@sites-list-font-size:				14px;
@sites-list-color:					#000;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				22px;
@sites-list-title-weight:			normal;
@sites-list-title-decoration:		none;

@parent-alert-padding:		10px;
@parent-alert-margin:		10px auto;
@parent-alert-border:		1px solid #000;
@parent-alert-font-size:	12px;
@parent-alert-color:		#000;
@parent-alert-bg:			#ffcc33;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/parent_alert.less";
@import "common/popup.less";
@import "common/mozaique.less";

h1 {
	text-align: center;
	margin: 0 0 20px;
	width: 100%;
	height: 85px;
	p {
		width: 100%;
		line-height: 15px;
		background: #000;
		font-size: 16px;
		padding: 2px;
	}
}
.mozaique {
	margin: 0 10px;
}
#language-switcher {
	float: right;
	margin: 0 10px;
}
.thumb-block {
	background: #333;
	width: 265px;
	height: 227px;
	.thumb-inside {
		margin: 5px 5px;
	}
	p {
		font-size: 12px;
		line-height: 15px;
		a {
			color: #fff;
			font-size: 13px;
		}
		&.metadata {
			color: grey;
			font-size: 12px;
			strong {
				font-weight: bold;
			}
		}
	}
}